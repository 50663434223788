/* # Colorbox

Styles for Colorbox
content-box sizing fix by Andrej P.
*/


@import '../variables.less';


#colorbox,
#cboxWrapper{position:absolute; top: 25px; left: 50px; z-index:9999; overflow:visible;}
#cboxOverlay{position:absolute; top:0; left:0; z-index:9999; overflow:hidden;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;
	background-color: transparent;
	}

#colorbox {
    padding: 25px 50px 25px;

    @media @tablet {
        padding: 25px 0 25px 0;
    }

    @media @mobile {
        padding: 25px 0 25px 0;
    }

	zoom: 1;
	background-color: #FFF;
	border-radius: 3px;
    box-shadow: 0 0 70px 0 #888;
    box-sizing:content-box;


	}
#cboxWrapper {
    @media @tablet {
        left: 0;
    }
    @media @mobile {
        left: 0;
    }
}

#colorbox BUTTON {border: none;}


#cboxLoadedContent {
	font-size: 13px;
	background-color: transparent;
	}

#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height: 100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block;}
.cboxIframe{width:100%; height:100%; display:block; border:0;}


#cboxOverlay{background:#000;}
        #cboxError{padding:50px; border:1px solid #ccc;}
        #cboxTitle{ position:absolute; top:-20px; left:0; color:#ccc; display: none!important; }
        #cboxCurrent{position:absolute; top:-20px; right:0px; color:#fff;}
        #cboxSlideshow{position:absolute; top:-20px; right:90px; color:#fff;}
		#cboxPrevious, #cboxNext {position:absolute; top: 0; width: 50px; height:100%; text-indent:-9999px; background-color: transparent; background-image: url('../i/colorbox/colorbox-arrows-v9.png'); background-repeat: no-repeat;}
        #cboxPrevious{left: -50px; background-position: 8px 50%;}
        #cboxNext{right: -50px; background-position: -112px 50%;}
        #cboxLoadingOverlay{background:#2E3640;}
        #cboxLoadingGraphic{background:#2E3640 url('../i/clearbox-loading.gif') no-repeat center center;}
        #cboxClose{border:0;padding:0;margin:0;position:absolute; top:-10px; right:-35px; display:block; background:url('../i/close.png') no-repeat top center; width: 22px; height:22px; text-indent:-9999px; z-index: 100}

/* fixing elements */
		#cboxTopLeft, #cboxTopCenter, #cboxTopRight, #cboxBottomLeft, #cboxBottomCenter, #cboxBottomRight, #cboxMiddleLeft, #cboxMiddleRight  {
			display: none;
			}
		#colorbox.cboxIE {*padding-bottom: 0px !important;}
