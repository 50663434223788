/* # GRID

Element width is calculated as x/12 of page flex-basis
You don't need to use full width (12 columns) - in that case row is by default aligned to left (legacy rule) - use .row.centered to center it


```
<div class="row">
<div class="span6"><p>Span 6</p></div>
<div class="span3"><p>Span 3</p></div>
<div class="span3"><p>Span 3</p></div>
</div>
	```

*/

@import '../variables.less';


/* ## gutter for the grid */

.AVsizer {
	padding-left: 20px !important;
  	padding-right: 20px !important;
	}

.row {
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
}

/* ## Desktop and tablet resolution  */
@media @tablet, @desktop {

  .row {
      flex-direction: row;
      justify-content: flex-start;
      align-items: stretch; // check if can be used globally!

      [class*="span"] {
          min-height: 1px;
          padding: 20px;
      }
  }

  /* flex-basis definitions */
  .span12 {
      flex-basis: 100%;
  }
  .span9 {
      flex-basis: 75%;
  }
  .span8 {
      flex-basis: 66.66666665%;
  }
  .span7 {
      flex-basis: 58.33333332%;
  }
  .span6 {
      flex-basis: 50%;
  }
  .span5 {
      flex-basis: 41.66666665%;
  }
  .span4 {
      flex-basis: 33.33333332%;
  }
  .span3 {
      flex-basis: 25%;
  }
  .span2 {
      flex-basis: 16.66666667%;
  }
}


/* features text in */
.row [class*="span"]  {
  &.overlap {
    position: absolute;
    max-width: 50%;
    right: 0;
  }
}


/* ## Tablet and mobile resolution */
 
@media @tablet, @mobile {

  // fixing size of the images inside grid
  [class*="span"] > img {
    max-width: 100%;
  }
}

/* ## Mobile resolution */
@media @mobile {
  .row {
    flex-direction: column;

        [class*="span"]  {
            min-height: 1px;
            padding: 20px;
            flex-basis: auto;
        }
    }   
}


/* ## Parameters */

.row {
     // switching left and right column (desktop, tablet)
     &.switch {    
      @media @tablet, @desktop {
          flex-direction: row-reverse;
      }
  }
  // horizontally centered columns (desktop, tablet)
  &.centered  {
      @media @tablet, @desktop {
          justify-content: center;
      }
  }
  // columns without padding (desktop, tablet, mobile)
  &.nopadding [class*="span"] {
      @media @tablet, @desktop {
          padding: 0px;
      }
      @media @mobile {
          padding: 0 0 20px;
      }
  }

}
