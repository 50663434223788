@charset "UTF-8";

/* Variables */
@import "../variables.less";

.cookie-bar {
	position: fixed;
    bottom: 0;
    left: 0px;
	z-index: 5000;
	width: 100%;
	max-height: 0;
	overflow: hidden;
	background: @Grey-0;
	text-align: center;
	color: @White;
	transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

	a {
		color: @White;
	}

	// Script added class
	&.visible {
		max-height: 1000px;
		transition: max-height 1s ease-in-out;
	}

	.AVsizer {
		padding-top: 25px;
		padding-bottom: 25px;
	}

	.content {
		display: flex;
		max-width: 1000px;
		flex-direction: row;
		justify-content: center;
		vertical-align: middle;
		padding-bottom: (16px * 0.5);

		@media @mobile {
			display: block;
		}
	}

	// Close icon
	.close {
		cursor: pointer;
		align-self: center;
		display: block;

		@media @mobile {
			display: block;
			margin-top: 20px;
		}
	}

	// Text
	p {
		flex: 1;
		margin: auto;
		padding: 0 50px 0 0;
		vertical-align: middle;
		text-align: left;
		color: @Grey-6;
		font-size: 14px;

		@media @mobile {
			display: block;
			text-align: center;
			padding-right: 0;
		}

		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}