/* # Basic page elements
==========

Provides styling for page elements, that are used both on the web and landingpages

So no to repeat thir definiton on
common-web.css
and
common-landingpage.css
they are put together in this component, that is included in both stylesheets

*/

@import '../variables.less';


/* Components import */
@import 'slide-top.less';
@import 'slider.less';


/* # Content holder definition */
#content-holder {
	text-align: center;
	position: relative;
	background-color: @ContentWhiteBackground;
	margin: 0px;
	padding: 0px;
	}

/*  # AV sizer definition
defines width of main elements content - header, content, footer */
.AVsizer {
	margin: 0px auto;
	text-align: left;
	position: relative;

		@media @mobile {
			text-align: center;
		}
	}


/* # Top element

## Standard top element

```
	<div id="top">
		<div class="AVsizer">
		</div>
	</div>
```
 */
#top {
	background-color: @ContentTopBackground;

	H1, H2 {
		color: @ContentTopTitle;
	}
	P {
		color: @ContentTopText;
	}
}

/* # Background colors

Basic background colors

```
<div id="content-holder">
	<div id="" class="bg-grey">
		<div class="AVsizer">
		</div>
	</div>
</div>
```

 */

/* ### Light backgrounds */ 
.bg-white {
  background-color: @ContentWhiteBackground;
  }
.bg-grey {
	background-color: @ContentLightBackground;
	}



/* ### Dark backgrounds */ 
.bg-dark {
	background-color: @ContentDarkBackground;
	color: @ContentDarkText;

	H1,
	H2,
	H3,
	H4,
	H5 {
		color: @ContentDarkTitle;
	}
	P {
		color: @ContentDarkText;
	}
}


.bg-darker {
	background-color: @ContentDarkerBackground;
	color: @ContentDarkerText;

	H1,
	H2,
	H3,
	H4,
	H5 {
		color: @ContentDarkerTitle;
	}
	P {
		color: @ContentDarkerText;
	}
}

.bg-darkblue {
    background-color: @BottomBackground;
    
    color: @ContentDarkText;

	H1,
	H2,
	H3,
	H4,
	H5 {
		color: @ContentDarkTitle;
	}
	P {
		color: @ContentDarkText;
	}
}

.bg-purple {
    background-color: #6534ac;
    
    color: @ContentDarkText;

	H1,
	H2,
	H3,
	H4,
	H5 {
		color: @ContentDarkTitle;
	}
	P {
		color: @ContentDarkText;
	}
}

.bg-orange  {
	background-color: #FF9000;
	color: #000;
	}
/* old repaint */
.bg-blue-grad {
	background-color: #f4f5f6;
}


/* box */
.AVbox {
	margin: 0 auto;
	background-color: #FFF;
	border: 1px solid #B8C0C9;
	text-align: left;
	position:relative;
	zoom: 1;
    border-radius: 8px;
    background: linear-gradient(to bottom,  #ffffff 80%,#efefef 100%);
}


/* # New/improved ico

Image icons with new / improved text

 */

IMG.txt-ico {
	position: relative;
	top: 3px;
	margin-right: 10px;
	}



/* # Rounded elements

Probably used only to display circular images, IE8 fallback are square images
 */
#content-holder .circle {
  border-radius: 50%;
}

