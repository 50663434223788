/* # Webfonts import  */

/* # Proxima Nova

.eot?#iefix is for IE6 - IE8
.woff2 is for latest versions of modern borwsers
.woff is for previous versions of modern borwsers
.ttf is for Safari, Android and iOS
 */

/* light - font-weight: 300 */
@font-face {
  font-family: "Proxima Nova";

  src: url("../o/f/300/proximanova-light-webfont.woff2") format("woff2"), 
       url("../o/f/300/proximanova-light-webfont.woff") format("woff"); 

  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

/* light - font-weight: 300, font-style: italic */
@font-face {
  font-family: "Proxima Nova";

  src: url("../o/f/300/proximanova-lightit-webfont.woff2") format("woff2"), 
       url("../o/f/300/proximanova-lightit-webfont.woff") format("woff"); 

  font-style: italic;
  font-weight: 300;
  font-display: swap;
}

/* regular - font-weight: 400 */
@font-face {
  font-family: "Proxima Nova";

  src: url("../o/f/400/proximanova-regular-webfont.woff2") format("woff2"), 
       url("../o/f/400/proximanova-regular-webfont.woff") format("woff"); 

  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

/* bold - font-weight: 700 */
@font-face {
  font-family: "Proxima Nova";

  src: url("../o/f/700/proximanova-bold-webfont.woff2") format("woff2"), 
       url("../o/f/700/proximanova-bold-webfont.woff") format("woff"); 

  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

// Font MierB

@font-face {
  font-family: "Mier B";
  src: url("../o/f/400/MierB03-Regular.woff2") format("woff2"), url("../o/f/400/MierB03-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap; // Fallback text is immediately rendered in the next available system typeface
}

//@font-face {
//  font-family: "Mier B";
//  src: url("../o/f/600/mierb-bold-webfont.woff2") format("woff2"), url("../o/f/600/mierb-bold-webfont.woff") format("woff");
//  font-style: normal;
//  font-weight: 600;
//  font-display: swap; // Fallback text is immediately rendered in the next available system typeface
//}

@font-face {
  font-family: "Mier B";
  src: url("../o/f/700/MierB03-Bold.woff2") format("woff2"), url("../o/f/700/MierB03-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: swap; // Fallback text is immediately rendered in the next available system typeface
}

@font-face {
  font-family: "Mier B";
  src: url("../o/f/800/MierB03-ExtraBold.woff2") format("woff2"), url("../o/f/800/MierB03-ExtraBold.woff") format("woff");
  font-style: normal;
  font-weight: 800;
  font-display: swap; // Fallback text is immediately rendered in the next available system typeface
}
