.unsupportedBrowserMessage {
	display: none;
	background: none repeat scroll 0 0 #ff7777;
	padding: 10px;
	text-align: center;
}

.notSupportedBrowser {
	.unsupportedBrowserMessage {
		display: block;
	}
}