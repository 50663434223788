/* # Slide to top icon 

Slide to the top section 
*/


@import '../variables.less';


#slide-top{
    cursor:pointer;
    position:fixed;
    bottom: 40px;
    right:50%;
    width:70px;
    height:70px;
    line-height:70px;
    margin-right:-630px;
    text-align:center;
    zoom: 1;
    background: url("../i/arrows/slide-top.png") no-repeat scroll 50% 50% transparent;
    z-index: 500;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease, visibility 1s ease;
  
    &.show {
      opacity: 1;
      visibility: visible;
    }
  
    SPAN {
          display: none;
      }
  
  }
  
  /* ## Media
  
  Slide top icon is displayed only for viewports bigger than 1240px
  
   */
  @media all and (max-width: 1240px) {
      #slide-top {
          display: none !important;
      }
  }
  @media all and (min-width:1241px) {
    #slide-top {
        position:fixed;
            bottom: 40px;
            right:7px;
            margin-right: 0;
    }
  }
  @media all and (min-width:1280px) {
      #slide-top{
            position:fixed;
            bottom: 40px;
            right:20px;
            margin-right: 0;
      }
  }