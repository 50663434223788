/* # Base-v11

Stylesheet for normalisation and basic elements definition
It is used in every web page, landing page and interstitial page

Is included to

* common-web-v11.css
* common-landingpage-responsive-v11.css
* common-landingpage-v11.css
* common-interstitial-v11.css

 */

/* # Imports */

/* Importing variables, mixins and components */
@import 'mixins.less';
@import 'variables.less';
@import 'fonts/fonts.less';
@import 'components/grid.less';
@import 'components/buttons.less';
@import 'components/text-styles.less';
@import 'components/tabs.less';
@import 'components/cookie-consent.less';
@import 'components/tooltip.less';
@import 'components/colorbox.less';
@import 'components/unsupported-browser-message.less';



/* # Border box model */
HTML {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

/* # General elements */
HTML, BODY {
	height: 100%;
}
BODY {
	position: relative;
	margin: 0;
	padding: 0;
	background-color: @BodyBackground;
	text-align: left;
	}

IMG {
	padding:0;
	margin:0;
}

FORM {
	margin:0;
	padding:0;
}

HR {
	color: transparent;
	height: 1px;
	border: none;
	border-bottom: 1px solid #EAEBEC;
	margin: 0;
}

TABLE {
	border-collapse: collapse;
	margin: 0px;
	padding: 0px;

	TD {
	margin: 0px;
	padding: 0px;
	}
}


/* # Hyperlinks */
A IMG {
	border: 0;
}


/* # Floats and clears */
.left {
	float:left;
}

.right {
	float:right;
}

.clear {
	clear:both;
}

.clearF{
	margin:0;
	line-height:0;
	padding:0;
	border:none;
	height:0;
	font-size:0;
	clear:both;
}
.clearfix:before,
.clearfix:after {
	content: " ";
	display: block;
	clear: both;
}

.none {
	clear:none;
}
.through {
	text-decoration: line-through;
	}

/* # Accessibility styles */

// focus
*:focus {outline: 1px solid #0087FF;}

// reader only content - hidden from viewport
.reader-only {
	position: absolute;
	left: -99999px;
	top: auto;
	width:1px;
	height:1px;
	overflow:hidden;
}

/* # Button */
button {
	border: none;
	padding: 0;
	background-color: transparent;
}

/* # Text decorations

Those are simply clases for basic text decorations

* underline
* line-through
* nowrap

 */
.underline{
	text-decoration:underline;
}
.line-through{
	text-decoration:line-through;
}
.nowrap {
  white-space: nowrap;
}


/* # Media content

Defines element visibility for different media contexts

* desktop
* tablet
* mobile

 */

/* #### desktop */
@media @desktop {
.tablet,
.mobile,
.hidden-desktop {
  display: none !important;
}
}

/* #### tablet */
@media @tablet {
.desktop,
.mobile,
.hidden-tablet {
    display: none !important;
  }
}

/* #### mobile */
@media @mobile {
.desktop,
.tablet,
.hidden-mobile {
    display: none !important;
  }

}


/* # Visibility

Specifies element visibility

  * .hide {display:none;}
  * .hidden {visibility:hidden;}
  * .visible {visibility:visible;}
  * .block {display:block;}
  * .inline {display:inline;}

 */

.hide {display:none;}
.hidden {visibility:hidden;}
.visible {visibility:visible;}
.block {display:block;}
.inline {display:inline;}


/* # No javascript message

Common element in the top of the page
 */
.noscript-message {
	background: none repeat scroll 0 0 #ff7777;
	padding: 10px;
	text-align: center;
}
.noscript-message a {
	&:visited,
	&:hover {
		color: #171B20;
	}
}

/* # Specials */

/* iframe.avast.com cookie request */
.iframe-blank {
	width: 0;
	height: 0;
	visibility: hidden;
}


/* background for cells in comparison table with tick - class added by JS - cell-tick */
.cell-tick{
  background-color: #f2f2f6;
}
