/* Text styles
==========
Provides basic styling of text elements and classes for standard elements

*/

@import '../variables.less';


/* # General

Helvetica Neue – it comes preinstalled with Mac OS, and has a 'light' font too, Roboto because of Android

'''font-family: 'Proxima Nova', 'Helvetica Neue', 'Roboto', Helvetica, Arial, sans-serif;'''
 */

BODY {
	font-family: 'Mier B','Proxima Nova', 'Helvetica Neue', 'Roboto', Helvetica, Arial, sans-serif;
}

/* # Headings

Headings definitions H1 - H5

Each heading has also class e.g. .like-h1, that can simulate heading styles on other element.
It should be used when using <hX> is not semantically correct

    <h1>Lorem ipsum</h1>

    <h2>Lorem ipsum</h2>

	<h3>Lorem ipsum</h3>

	<h4>Lorem ipsum</h4>

	<h5>Lorem ipsum</h5>

*/
H1, H2, H3, H4, H5, [class*="like-h"] {
	position: relative; // Added because of .label component
	color: @HeadingText;
	font-weight: 700;
	margin: 0;
	padding: 0;

	A {
		color: @HeadingText;

		&:hover {
			color: lighten(@HeadingText, 10%);
		}
	}
}

H1,
.like-h1 {
	font-size: 62px;
	line-height: 1.1;

	.smaller {
		font-size: 60px;
	}

	@media @mobile {
		font-size: 50px;
	}
	@media @small-mobile {
		font-size: 40px;
	}
}


H2,
.like-h2 {
	font-size: 50px;
	line-height: 1.1;

	@media @mobile {
		font-size: 40px;
	}
	@media @small-mobile {
		font-size: 30px;
	}
}
H3,
.like-h3 {
	font-size: 40px;
	line-height: 1.1;

	@media @mobile {
		font-size: 35px;
	}
	@media @small-mobile {
		font-size: 25px;
	}
}
H4,
.like-h4 {
	font-size: 26px;
	line-height: 1.3846;
	text-decoration: none;
}
H5,
.like-h5,
.like-h5-tabs
 {
	font-size: 21px;
	line-height: 1.2;
	text-decoration: none;
}

/* #### Subheadings

To be used only together with H1, H2, H3
 */

/* subheading below H1 */
P.subh1	{
font-size: 24px;
line-height: 1.4167;
}

/* subheading below H2 */
P.subh2 {
font-size: 22px;
line-height: 1.3636;
}

/* subheading below H3 */
P.subh3 {
font-size: 18px;
line-height: 1.39;
}

/* # Paragraphs */

/* #### General */
P,
TD,
EM,
INPUT,
SELECT,
TEXTAREA {
	color: @MainText;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.375;
}
P {
	margin: 0;
	padding: 0 0 15px;
}


/* form elements by default do not inherit font-family property */
INPUT,
SELECT,
TEXTAREA {
	font-family: inherit;
}

/* #### Featured

Can only go below H3 or H4; used on request for featured content */
P.featured {
	font-size: 18px;
	line-height: 1.3889;
}
/* #### Smaller

Smaller than typical paragraph font size. Added on Kuba's request */
P.smaller {
	font-size: 15px;
}


/* # links

Default hyperlink

 */
 A {
	color: @HyperlinkText;
	margin: 0;
	padding: 0;
	outline: none;
	text-decoration: none;
	cursor: pointer;
	font-size: inherit;
	font-weight: inherit;

	&:hover {
		color: lighten(@HyperlinkText, 10%);
	}

	&:not(.button):focus {
		outline: 2px solid #0087FF;
	}
}


/* Class for links which requires higher visibility */
.highlighted-link {
	color: @HyperlinkHighlighted;

	&:hover {
		color: @HyperlinkHighlighted;
		text-decoration: underline;
	}
}


/* # Lists */

/* #### List item */
LI {
	color: @MainText;
	margin: 0;
	padding: 0;
	font-weight: 300;
	line-height: 1.43;
}

/* #### Unordered lists */
UL {
	margin: 0 0 0 17px;
	padding: 0 0 15px;
	}

/* without icon */
UL.no-img {
	list-style: none;
	margin-left: 0;
	}

/* with icon */
UL.ico {
	list-style: none;
	margin-left: 0;

		LI {
		margin: 0;
		padding-left: 30px;
		background-repeat: no-repeat;
		background-position: 0% 50%;
		background-color: transparent;
		}
	}

/* with orange bullet */
UL.bullet-orange  {

		LI {
			padding-left: 17px;
			background-image: url(../i/bull-orange.png);
			margin-left: 15px;
			background-position: 0 7px;
		}
	}

/* #### Ordered lists */
OL {
	margin: 0 0 0 20px;
	padding: 0 0 15px;
	}


/* ### Linear elements */
B, I, SPAN {
	font-size: inherit;
	color: inherit;
}
SPAN {
	font-weight: inherit;
}
B,
STRONG {
	font-weight: 700;
}
I {
	font-style: italic;
}
