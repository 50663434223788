/* Tabs
==========

Set of styles for the Tabs element

Used both on web and landingpages

*/

@import '../variables.less';


/* # TABS */

/* tabs anchor */
#content-holder #tabs-anchor .AVsizer {
	padding: 0;
	width: 100%;
	min-height: 60px;
	/* this overrides the default max-width setting for AV sizer, which is 1056px */
	max-width: none; 
	}

#tabs-anchor {
	position: fixed;
    visibility: hidden;
	z-index: 500;
    top: 0;
    width: 100%;
    background-color: #FFF;
    transition: .5s ease-in-out;
    /* Move outside from viewport with transform only. Then browsers without transition can use visibility as fallback */
    transform: translate(0, -60px);
    border-bottom: 2px solid @TabsBorder;
	box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.2);

    @media @desktop {border-bottom: none;}
    
	&:after {
	   content: "";
	   visibility: hidden;
	   display: block;
	   height: 0;
	   clear: both;
	  }

	.AVsizer {
		background: transparent url(../i/avast-software-smaller-dark.png) 80px 50% no-repeat;
	}

	// List styles
	 UL {	    
		display: flex;
		justify-content: center;
		flex-direction: row;
		flex-wrap: nowrap; // Lets try more lines in a tab
		margin: 0 auto;
		padding: 0;
		list-style: none;
	    

		&:after {
		content: "";
		visibility: hidden;
		display: block;
		height: 0;
		clear: both;
		}
	

		LI {
			position: relative;
			list-style-type: none;
			margin-bottom: -1px;

			&::before,
			&:last-child::after {
				display: block;
				position: absolute;
				top: 20%;
				content: "";
				width: 1px;
				height: 60%;
				background-color: @Grey-8;
			}
			&:before {
				left: 0;
			}
			&:last-child::after {
				right: 0;
			}

		

			A {
				display: block;
				min-height: 70px;
				padding: 25px 25px 0;
				text-transform: uppercase;
				text-decoration: none;
				color: @Grey-6;
				border-bottom: 2px solid transparent;
				font-size: 14px;
				font-weight: 400;
				text-overflow: ellipsis;
				// white-space: nowrap; Lets try wrapping instead of creating a second row of tabs

				&:hover,
				&.active {
				border-bottom: 2px solid @Orange;
				}

				&.active {
					color: @Orange;
				}

				// Label
				.label {

					@media @desktop {
						position: relative;
						top: -2px;
						margin: 0 0 0 10px;
					}
					@media @tablet {
						position: absolute; // To make space lets move labels above the text
						z-index: 100;
						top: 0;
						right: -5px;
					}
				}
			}
		}
	}

	/* other elements */
	.like-h5 {
            display: inline-block;
            margin: 14px 60px 0;
            height: 32px;
            line-height: 32px;
            padding: 0 0 0 48px;
            background-repeat: no-repeat;
            background-position: 0 50%;
			background-color: transparent;	
		}

		.like-h5-tabs {
			display: inline-block;
			margin: 14px 60px 0;
			height: 45px;
			line-height: 32px;
			padding: 0 0 0 48px; 		
			}		
		
	
        .buttons {
            float: right;
            position: relative;
            top: 7px;
            padding: 0 20px;
        
        	.button {
            position: relative;
            margin: 0 0 0 20px;
        	} 

        }

}

/* sticky state */
#tabs-anchor.sticky {
	    position: fixed;
	    visibility: visible;
	    z-index: 500;
	    top: 0;
	    transform: translate(0, 0);
	    }
/* no-sticky state */	  
#tabs-anchor.nosticky{
	    visibility: visible !important;
	    position: relative !important;
	    transform: none !important;
	    top: auto;
	}

.tabs-anchor-wrap {
	&.partscreen {
		height: 62px;

		#tabs-anchor {
			position: relative;
			transform: translate(0, 0);
			visibility: visible;

			&.sticky {
				position: fixed;
				z-index: 500;
				top: 0;
			}
		}
	}
}
/* # Mobile */
@media @mobile { 

	.tabs-anchor-wrap {
		display: none;
	}
	// For older version of code without .tabs-anchor-wrap wrapping element
	#tabs-anchor {
		display: none;
	}


	
	#tabs [id*="tab"] {
		display: block !important;
		border-bottom: 1px solid #EAEBEC;
	}
	 #tabs H2 {
	    font-size: 22px;
	}

}


/* localisation */

[class*="mod-he"],
[class*="mod-ar"] {

	#tabs-anchor {

	    UL LI {
	    float: right !important;
	    direction: ltr;
	    margin-left: 0px;

	        A {
	        border-left: 1px solid #E9EAEC;
	        border-right: none;
	        }
	    }
	    UL LI:last-child A {
	        border-left: none;
	    }
	     
	    /* swaps div with buttons to opposite side */    
	    .buttons {
	        float: left;
	    }
	}
	    
	#tabs-navigation UL LI {
	    float: right !important;
	}
}