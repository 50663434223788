/* # SLIDER */


@import '../variables.less';


.slider {
    position: relative;
    }
.slider [class*="slide"] {
	*position: relative !important; /*  IE 7 fix */
    }
.slider .s-nav {
    display: block;
    position: absolute;
    height: 100%;
    width: 60px;
    position: absolute;
    top: 0;
    z-index: 500
    }
.slider .s-nav.prev {
    left: 0;
    }
.slider .s-nav.next {
    right: 0;
    }
.slider .s-nav SPAN {
    position: absolute;
    top: 40%;
    display: block;
    width: 40px;
    height: 80px;
    background-image: url(../i/product-home/slider-arrows.png);
    background-repeat: no-repeat;
    }
.slider .s-nav.prev SPAN {
    background-position: 0% 100%;
    right: 0;
    }
.slider .s-nav.next SPAN {
    background-position: 100% 100%;
    left: 0;
    }
.slider .s-nav.prev:hover SPAN {
    background-position: 0% 0%;
    }
.slider .s-nav.next:hover SPAN {
    background-position: 100% 0%;
    }

.slider .control {
	text-align: center;
	position: absolute;
	left: 48%;
	bottom: 20px;
	z-index: 99;
	text-align: center;
	}

.slider .control SPAN.bullet {
	display: inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
	margin: 3px;

    zoom: 1;
    position: relative;
    top: 3px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
	background-color: #FFF;
	 border: 1px solid #00A7D1;
	}

.slider .control .active SPAN.bullet {
    background-color: #00A7D1;
    border: 1px solid #00A7D1;
 }

 .slider .control .bx-pager-item {
	 float: left;
	 }
 .slider .s-nav {
    opacity: 0;
    transition: opacity .25s ease-in-out;
  }
 .slider:hover .s-nav {
    opacity: 1;
  }
  .slide-text .span4 {
  	position: relative;
  }
  .slide-text .active:after {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 50%;
	border: solid transparent;
	border-width: 14px;
	border-bottom-color: #f4f5f6;
	top: -31px;
	z-index: 1000;
}