@charset "utf-8";
/* CSS Document */

/* DESKTOP and TABLET - min-width: 768px ------------------------------------- */

@import '../variables.less'; 


body:after {
    content: 'widescreen';
    display: none;
}	

/*  HEADER */
#header {

	.AVsizer { 
		height: 100px;
		width: auto;
		min-width: 768px;
		max-width: 1920px;
		}

	/* Avast logo */
	.logo {
		position: absolute;
		left: 50px;
		top: 28px;
		}

	/* 400M users text */	
	P { 
		// OO-10361 - romoving 400M users
		// display: inline-block;
		display: none;
		position: absolute;
		top: 36px;
		right: 60px;
		padding: 0;
		text-align: right;
		font-weight: 400;


			SPAN.orange {
			position: relative;
			z-index: 1;
			top: 1px;
			margin: 0 1px;
			font-size: 20px;
			color: @HeaderTextImportant;
		}
	}
}


/* # CONTENT

Content-holder definition - element that contains all included content

 */	
#content-holder {
	min-width: 768px;
	}
		
/* # AV sizer 

Defines width of main elements - header, content, footer

 */

 /* #### General definition for all elements */
.AVsizer {
    max-width: 1056px;
	}
	
	
/* # FOOTER

Page footer, contains copyright
 */
#footer {
	text-align: center;
	margin: 0;
	color: @FooterText;
	
	.AVsizer {
		height: 100px;
	    padding: 40px 0 0;
		}
	H4 {
		margin: 0px;
		padding: 15px 0px 10px;
		font-size: 12px;
		font-weight: bold;
		text-transform: uppercase;
		}
	P {
		font-size: 12px;
		padding: 0;
		margin: 0;
		}
	A {	
	text-decoration: none;
	color: @FooterLink;
	
		&:hover {	
		color: lighten(@FooterLink, 20%);
		}
	}
	
	UL {
		margin: 0;
		padding: 0 0 40px;

			LI {
			line-height: 18px;
			list-style-type: none;
			}
		}
	DIV.col {
		width: 191px;
		}
	DIV.copy {
		position: relative;

		P SPAN {
			margin-right: 10px;
			}
		P A {
			margin-left: 5px;
			margin-right: 5px;
			}
		}
	DIV.copyLP {
		border-top: 3px solid #3a424d;
		padding-bottom:0 !important; /*beat style from DIV.separator*/
		}

	/* footer ico */
	UL.ico LI {
		padding: 0;

			A {
			display: block;
			padding-left: 25px;
			background-repeat: no-repeat;
			background-color: transparent;
			background-image: url("../i/footer-icons.png");
			}
		}

	.blog A {
		background-position: 0 2px;
		}
	.fb A {
		background-position: 0 -23px;
		}
	.twitter A {
		background-position: 0 -48px;
		}
	.gplus A {
		background-position: 0 -73px;
		}
	.youtube A {
		background-position: 0 -98px;
		}

}
	
