/* # BUTTONS

Styles for creating all types of buttons

Html syntax is

```<a class="button COLOR SIZE OTHER"><span>Text</span></a>```

Contains
  * general button definition
  * sizes
  * colors
  * arrows
  * special buttons

 */
@import '../variables.less';


/* # General button definition

Defines basic button behaviour

* class .button
* hover state normalization
* basic styles for SPAN element

 */

.button {
  display: inline-block;
  border-radius: 3px;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  position: relative;

  &:hover {
  text-decoration: none;
  }

  /* shadow - with exceptions for some button types */
  box-shadow: 0px 2px 8px 0px rgba(41, 41, 51, 0.25);

  &.transparent,
  &.faux {
    box-shadow: none;
  }

  &:focus span {
    box-shadow: 0px 2px 8px 0px rgba(41, 41, 51, 0.25), 0px 0px 0px 3px @HyperlinkText;
  }

  SPAN {
    margin: 0 auto 0;
    padding: 14px 20px 12px;
    display: inline-block;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 17px;
    background-repeat: no-repeat;
    position:relative;
    zoom: 1;
    border-radius: 2px;
    transition: all 0.1s ease-in-out;
  }

/* Additional text

<a class="button twoline blue"><span>Blue button<i>Additional text</i></span></a>
 */
  I {
      display: block;
      font-weight: 300;
      font-style: normal;
      text-transform: none;
      text-align: center;
    }
}



/* # Button colors  */

/* #### Blue button (default - currently orange, untill the .blue class will be replaced with .orange)

    <a class="button blue"><span>Blue button</span></a>

 */
.button.blue SPAN {
  color: @lightButtonTextColor;
  background-color: @pineGreenButtonBgColor;

    &:hover {
    background-color: @pineGreenButtonBgColorHover;
    }
}

/* #### Orange button (default)

    <a class="button blue"><span>Blue button</span></a>

 */
.button.orange SPAN {
  color: @lightButtonTextColor;
  background-color: @pineGreenButtonBgColor;

  &:hover {
    background-color: @pineGreenButtonBgColorHover;
  }
}

/* #### Green button (default)

    <a class="button green"><span>Green button</span></a>

 */
.button.green SPAN {
  color: @lightButtonTextColor;
  background-color: @greenButtonBgColor;

  &:hover {
    background-color: @greenButtonBgColorHover;
  }
}

/* #### Purple button

  <a class="button purple"><span>Green button</span></a>

*/
.button.purple SPAN {
  color: @lightButtonTextColor;
  background-color: @purpleButtonBgColor;

  &:hover {
    background-color: @purpleButtonBgColorHover;
  }
}

/* #### Grey button (secondary)

    <a class="button grey"><span>Grey button</span></a>

 */
.button.grey SPAN {
  color: @lightButtonTextColor;
  background-color: @greyButtonBgColor;

  &:hover {
    background-color: @greyButtonBgColorHover;
  }
}

/* #### White button (for dark backgrounds)

    <a class="button white"><span>White button</span></a>

 */
.button.white SPAN {
  color: @whiteButtonTextColor;
  background-color: @whiteButtonBgColor;
  box-shadow: inset 0 0 0 0 @Grey-7;

  &:hover {
    color: @whiteButtonTextColorHover;
  }
}
/* #### Transparent button (for less important links, to be used on dark backgrounds)

    <a class="button transparent"><span>Transparent button</span></a>

 */
.button.transparent SPAN {
  color: @lightButtonTextColor;
  background-color: @Transparent;
  box-shadow: 0 0 0 1px @White inset;

  &:hover {
    background-color: fadein(@Transparent, 10%);
  }
}

.button.transparent:focus SPAN {
  box-shadow: 0 0 0 1px @White inset, 0px 0px 0px 3px @HyperlinkText;
}

/* #### Transparent button light (for less important links, to be used on light backgrounds)

    <a class="button transparent light"><span>Transparent button light</span></a>

 */
.button.transparent.light SPAN {
  color: #677280;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: inset 0 0 0 1px @Grey-7;
}
.button.transparent.light SPAN:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.button.transparent.light:focus SPAN {
  box-shadow: inset 0 0 0 1px @Grey-7, 0px 0px 0px 3px @HyperlinkText;
}

/* #### Greylight button

    <a class="button greylight"><span>Greylight button</span></a>

 */
.button.greylight  SPAN {
  color: @lightButtonTextColor;
  background-color: @Grey-7;
  box-shadow: inset 0 0 0 1px @Grey-6;
}
.button.greylight  SPAN:hover {
  background-color: @Grey-7;
}
.button.greylight:focus SPAN {
  box-shadow: inset 0 0 0 1px @Grey-6, 0px 0px 0px 3px @HyperlinkText;
}

/* #### Faux button

Button that looks like link, but might be used together with buttons
No side padding - it will be vertically aligned with text

    <a class="button faux"><span>Faux button</span></a>

 */
.button.faux SPAN {
  color: @fauxButtonTextColor;
  padding: 14px 0 13px;
  background-color: transparent;
}
.button.faux SPAN:hover {
  color: @fauxButtonTextColorHover;
}

/* # Button border removal

Class .noborder removes border from any butten - used especially for white button */
.button.noborder SPAN {
  box-shadow: none;
}
.button.noborder:focus SPAN {
  box-shadow: 0px 0px 0px 3px @HyperlinkText;
}



/* # Button sizes */

/* #### Normal button (basic size, no size class needed)

    <a class="button blue"><span>Normal button</span></a>

 */

/* #### Big button (size +1)

    <a class="button blue big"><span>Big button</span></a>

 */
.button.big  SPAN {
  padding: 17px 26px 16px;
}

/* #### Large button (size +2)

    <a class="button blue big"><span>Large button</span></a>

 */
.button.large  SPAN {
  font-size: 19px;
  font-weight: 700;
  padding: 24px 30px 23px;
  line-height: 22px;
}



/* # Two-line buttons */

/* #### sizes */
.button.large.twoline SPAN {
  padding: 16px 30px 17px;
}

.button.big.twoline SPAN {
  padding: 13px 26px;
}

.button.twoline SPAN {
  padding: 12px 20px 10px;
}


/* #### Second line in two-line buttons */

.button.large span i {

  font-size: 0.737em;
  line-height: 1.143;
}

.button.big span i {

  font-size: 0.813em;
  line-height: 1.385;
}

.button span i {

  font-size: 0.8em;
  line-height: 1.167;
}


/* Buttons same height as two-line buttons */

.button.large.twolineheight SPAN {
    padding: 25px 30px 24px;
}

.button.big.twolineheight SPAN {
    padding: 22px 26px;
}

.button.twolineheight span {
    padding: 18px 20px;
}




/* # Buttons with symbols */

.button {

  /* ### Symbols before text */

  /* Win logo button */
  &.logo-win span::before {
    display: inline-block;
    background-position: 50% 50%;
    content: "";
    position: relative;
    top: 1px;
    width: 15px;
    height: 15px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-image: url('@{buttons_imgPathParefix}i/ico/buttons/logo-windows.svg');
  }

  &.logo-mac span::before {
    display: inline-block;
    background-position: 50% 50%;
    content: "";
    position: relative;
    top: 1px;
    width: 15px;
    height: 15px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-image: url('@{buttons_imgPathParefix}i/ico/buttons/logo-mac.svg');
  }

  &.logo-android span::before {
    display: inline-block;
    background-position: 50% 50%;
    content: "";
    position: relative;
    top: 1px;
    width: 15px;
    height: 15px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-image: url('@{buttons_imgPathParefix}i/ico/buttons/logo-android.svg');
  }

  &.logo-ios span::before {
    display: inline-block;
    background-position: 50% 50%;
    content: "";
    position: relative;
    top: 1px;
    width: 15px;
    height: 15px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-image: url('@{buttons_imgPathParefix}i/ico/buttons/logo-ios.svg');
  }

  &.white.logo-win span::before {
    background-image: url('@{buttons_imgPathParefix}i/ico/buttons/logo-windows-dark.svg');
  }
  &.white.logo-mac span::before {
    background-image: url('@{buttons_imgPathParefix}i/ico/buttons/logo-mac-dark.svg');
  }
  &.white.logo-android span::before {
    background-image: url('@{buttons_imgPathParefix}i/ico/buttons/logo-android-dark.svg');
  }
  &.white.logo-ios span::before {
    background-image: url('@{buttons_imgPathParefix}i/ico/buttons/logo-ios-dark.svg');
  }

  /* ### Symbols after text */

  &.download,
  &.download-line,
  &.add,
  &.cart,
  &.google-store,
  &.apple-store,
  &.down,
  &.up,
  &.forward,
  &.back {

    span::after {
      display: inline-block;
      background-position: 50% 50%;
      content: "";
      position: relative;
      top: 1px;
      width: 14px;
      height: 14px;
      margin-left: 10px;
      background-repeat: no-repeat;
    }
  }

  /* Download button */
  &.download span::after {
    background-image:  url('@{buttons_imgPathParefix}i/ico/buttons/icon-download.png');
  }

  &.download-line span::after {
    background-image:  url('@{buttons_imgPathParefix}i/ico/buttons/icon-download-line.png');
  }

  /* Add button */
  &.add span::after {
    background-image:  url('@{buttons_imgPathParefix}i/ico/buttons/icon-add.png');
  }

   /* Cart button */
   &.cart span::after {
    background-image: url('@{buttons_imgPathParefix}i/ico/buttons/icon-cart.png');
  }

  /* Google store button */
  &.google-store span::after {
    background-image: url('@{buttons_imgPathParefix}i/ico/buttons/logo-google-store.png');
  }

  /* Apple store button */
  &.apple-store span::after {
    background-image: url('@{buttons_imgPathParefix}i/ico/buttons/logo-apple-store.png');
  }

  /* Arrow down button */
  &.down span::after {
    background-image:  url('@{buttons_imgPathParefix}i/ico/buttons/icon-arrow-down.png');
  }
  /* Arrow up button */
  &.up span::after {
    background-image:  url('@{buttons_imgPathParefix}i/ico/buttons/icon-arrow-up.png');
  }
  /* Arrow forward button */
  &.forward span::after {
    background-image:  url('@{buttons_imgPathParefix}i/ico/buttons/icon-arrow-left.png');
  }
  /* Arrow back button */
  &.back span::after {
    background-image:  url('@{buttons_imgPathParefix}i/ico/buttons/icon-arrow-right.png');
  }
}

/* button hint */
.button-hint {
  color: #fff;
  text-align: center;

  a {
    color: #fff;
    text-decoration: underline;

    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }
}
