@charset "utf-8";
/* CSS Document */

@import '../variables.less'; 

/* MOBILE - max-width: 767px ------------------------------------- */
/**
 * Do not remove. Sets device screen for js.
 */
body:after {
    content: 'mobile' !important;
    display: none !important;
}

	
/* # HEADER */
#header { 

	.AVsizer { 
	height: 70px;
	width: auto;
	text-align: right;
	}	

/* Avast logo */
	.logo {
		left: 15px;
		position: absolute;
		top: 13px;
	}

	/* 400M users text */
	P {
		// OO-10361 - romoving 400M users
		// display: inline-block;
		display: none;
		margin: 0 auto;
        padding: 10px 5px 0 100px;
		font-weight: 400;
        max-width: 415px;

		SPAN {
		font-weight: bold;
		}
		SPAN.orange { 
			position: relative;
			z-index: 1;
			top: 1px;
			margin: 0 1px;
			font-size: 20px;
			color: @HeaderTextImportant;
		}
	}

}
		
/* # AVsizer 

 defines width of main elements - header,  footer */
.AVsizer {
	width: 100%;
	max-width: 1008px;
}


/* # CONTENT

Makes left and right padding inside content holder 

*/	
#content-holder {
	width: 100%;
	max-width: 1008px;
	}

#content-holder .AVsizer {
	width: auto;
}

	
/* # Tabs 

Tabs are hidden im mobile resolutions
*/
#tabs-anchor {
		 display: none;
		 }

	
/* # FOOTER */
#footer {
	text-align: center;
	margin: 0;
	padding: 0 0 40px;
	font-size: 11px;

	.AVsizer {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	
	A {	
		text-decoration: none;
		color: @FooterLink;

		&:hover {	
		color: lighten(@FooterLink, 10%);
		}
	}

	P {
		padding: 0;
		margin: 0;
		color:  @FooterText;
		font-size: 12px;
		}
	P.left,
	P.right {
		float: none;
	}
	DIV.copy {
		position: relative;
		
	P {
		padding: 0 0 25px;
		}
	P SPAN {
		margin-right: 10px;
		}	
	P A {
		margin-right: 5px;
		}
	}
H4 {
	margin: 0px;
	padding: 15px 0 0;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	}
UL {
	margin: 0;
	padding: 0;
	
		LI {
		line-height: 18px;
		list-style-type: none;
		margin-right: 15px;
		display: inline-block;
		}
	}
DIV.col {
	padding: 0 20px 0;
	width:auto;
	}
DIV.col.left {
	float: none;
	}
}
