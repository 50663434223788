/* # Tooltip

Styles for tooltip
Please note that <em> element is reserved only for tooltip, so do not use it in your code

```<div id="tooltip" class="tooltip-bubble" style="top: 2122.65px; left: 1349.18px; display: none;">
<em>A software or hardware device designed and configured to allow access for authorized communications and deny any transmissions that are unauthorized.</em>
</div>```

 */
 

@import '../variables.less';


.tooltip-bubble {
	position: absolute;
	width: 250px;
	border: 1px solid @ContentBorder;
	border-radius: 3px;
	padding: 10px 12px;
	box-shadow: 0px 1px 3px @Grey-8;
	z-index: 9999;
	background-color: @White;
	background: linear-gradient(@White, @Grey-9);
}
.tooltip-bubble:before {
   content: "";
   position: absolute;
   top: -10px;
   left: 20px;
   width: 0;
   height: 0;
   border-bottom: 9px solid @ContentBorder;
   border-left: 9px solid rgba(255, 255, 255, 0);
   border-right: 9px solid rgba(255, 255, 255, 0);
   z-index: 9998;
}
.tooltip-bubble:after {
   content: "";
   position: absolute;
   top: -8px;
   left: 20px;
   width: 0;
   height: 0;
   border-bottom: 9px solid @White;
   border-left: 9px solid rgba(255, 255, 255, 0);
   border-right: 9px solid rgba(255, 255, 255, 0);
   z-index: 10000;
}
/* <em> is reserved for tooltip, do not use within page content */
.tooltip-bubble em {
	font-size: 14px;
	font-style: normal;
}